import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../assets/LogoAsset/SVG/Asset 2.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,

};
export default function NavBar(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const [navbar, setNavbar] = useState(false);
    const [clientWindowHeight, setClientWindowHeight] = useState("");
    const [changeBg, setChangeBg] = useState(false)
    const [activeTextColor, setactiveTextColor] = useState({
        home: "",
        about: "",
        Faq: ""
    })
    // const [aboutTextColor, setAboutTextColor] = useState("")
    // const [FAQTextColor, setFAQTextColor] = useState("")


    const handleScroll = () => {
        setClientWindowHeight(window.scrollY);
    };

    const activeText = "text-[#060606]";

    useLayoutEffect(useCallback(() => {

        switch (location.pathname) {
            case "/":
                setactiveTextColor({ home: activeText }
                )
                break;
            case "/about":
                setactiveTextColor({
                    about: activeText
                });
                break;

            case "/FAQ/general":
                setactiveTextColor({
                    Faq: activeText
                }
                );
        }
    }), [location.pathname])

    return (
        <nav className={changeBg ? `w-full bg-glossy fixed dark:bg-[#00000070]  z-40 md:bg-white glossy-background ` : `w-full z-40 fixed bg-glossy dark:bg-[#00000070]  glossy-background `}>
            <div className=" space-x-9 px-3 sm:px-4 lg:px-20 xl:px-36 mx-auto  md:items-center md:flex">
                <div>
                    <div className="flex  items-between justify-between py-3 md:py-5 md:block">
                        <Link to='/' >
                            <Logo width={100} height={30} />
                        </Link>
                        <div className="md:hidden">
                            <button
                                className="p-2 text-white rounded-md outline-none bg-[#F5F7FA]  focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {!navbar && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 text-black h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div
                        className={`flex  pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "hidden" : "hidden"
                            }`}
                    >
                        <div className="  flex justify-start">
                            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                                <Link to='/' className={" text-zinc-500 text-base font-normal  " + activeTextColor.home}>Home</Link>
                                <Link to='/about' className={" text-zinc-500 text-base font-normal  " + activeTextColor.about}>About us</Link>
                                <Link to='/FAQ/general' className={" text-zinc-500 text-base font-normal  " + activeTextColor.Faq} >FAQ</Link>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className=" hidden md:flex md:order-2">
                    <button
                        onClick={() => {
                            window.open("https://kinra.ng/FAQ/general", "_blank")
                        }}
                        className=' bg-primaryLight px-5 py-2.5 border-none rounded-[32px] font-bold text-white'
                        type='submit'
                    >Learn More</button>

                </div>
                <Modal
                    open={navbar}

                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className='h-[95vh]  md:hidden'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <div className=" border-b-2 pb-2 px-4 pt-4 border-[#E6E6E7] flex justify-between">
                                <div className="">
                                    <img src={"https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/FCMImages%2Flogo.6acfcb1e.png?alt=media&token=f876be3b-dd9e-4d67-aed0-5b7acc11de15&_gl=1*xfz8u9*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5OTAzNDk0OS4xNTkuMS4xNjk5MDM1ODE2LjI2LjAuMA.."} className=" h-8" alt=" logo" />
                                </div>

                                <button
                                    className="p-2  text-white rounded-md outline-none bg-[#F5F7FA]  focus:border"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 text-black h-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </Typography>
                        <div className="  mt-2">
                            <div className=" p-4">

                                <div className="  flex justify-center">
                                    <ul className="items-start justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                                        <Link to='/' className={" block text-zinc-500 text-base font-normal  text-center " + activeTextColor.home}>Home</Link>
                                        <Link to='/about' className={" block text-zinc-500 text-base font-normal text-center " + activeTextColor.about}>About us</Link>
                                        <Link to='/FAQ/general' className={" block text-zinc-500 text-base font-normal text-center  " + activeTextColor.Faq} >FAQ</Link>
                                    </ul>

                                </div>
                                <div className=" mt-24">

                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </nav>


    );
}