import { useState } from "react"
import { StylesStates } from "../../StylesStates"

export default function HeroUI() {
   const [HeroUI, setHeroUI] = useState(StylesStates.heroUIStyle)
   return (
      <div
         className="w-full h-full"
      >
         <div
            className={"  " + HeroUI}
         >
            <div
               className='w-full font-Kumbh500 font-semibold text-white px-1 lg:text-[4rem] text-center text-[1.7rem] sm:text-3xl md:text-5xl tracking-tight'
            >
               Privacy Policy
            </div>
            <div
               className='font-Kumbh400 font-normal text-zinc-300 px-1 max-w-[75rem] lg:text-[1.5rem] text-sm text-center sm:text-base leading-4'
            >
               In keeping with our resolution, we have created this Privacy Policy to explain your privacy rights while visiting our website or using our digital platforms,
               and about how we collect, use, share, and safeguard your Personal Data.
            </div>
         </div>
      </div>
   )
}