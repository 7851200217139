import CTAUI from "../components/NavBar/Home/CTAUI";
import FeatureUI from "../components/NavBar/Home/FeatureUI";
import Footer from "../layouts/Footer";
import HeroUI from "../components/NavBar/Home/HeroUI";
import LeadUI from "../components/NavBar/Home/LeadUI";
import loaderAnim from "../assets/animations/loader.json";
import NavBar from "../layouts/NavBar";
import { useEffect, useState } from "react";
import { baseUrl } from "../webbase";
import axios from "axios";
import { CircleLoader } from "react-spinners";

import Lottie from 'react-lottie';
import ShoppingJourney from "../components/journey/shopper";
export default function Home() {

   const [products, setProducts] = useState([]);
   const [isloading, setIsloading] = useState(false);
   const [productsByVendor, setPByV] = useState([]);

   useEffect(() => {
      const getProducts = async () => {
         setIsloading(true)
         const url =
            baseUrl +
            "/api/discover-products";

         const response = await axios.get(url, {
            headers: {},
         }).finally(() => {
            setTimeout(() => setIsloading(false), 2500)

         });

         if (response?.data.code == 200) {
            setProducts(response?.data.data.sort((a, b) => 0.5 - Math.random()));
         } else {
            return [];
         }
      };
      //   getProducts();
   }, []);
   return isloading ?
      (
         <div className="flex h-96 w-full justify-center items-center my-32">
            <Lottie options={{
               loop: true,
               autoplay: true,
               animationData: loaderAnim,
               rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
               }
            }}
               height={200}
               width={200}
            />
         </div>) :
      (<div className="overflow-x-hidden h-full w-full dark:bg-black">

         <NavBar />

         <main
            className="pb-32 pt-24"
         >
            <HeroUI products={products} />
            <section className="px-3 sm:px-4 lg:px-20 xl:px-24">
               <ShoppingJourney />
            </section>

            <section className="px-3 sm:px-4 lg:px-20 xl:px-24">
               <LeadUI />
               <CTAUI />
            </section>
         </main>

         <footer>
            <Footer />
         </footer>
      </div >
      )


} 
