import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import app from "./firebase.js";
import Seller from './pages/Seller.js';


function Router() {
  return (
    <Routes>

      <Route path="/" Component={Home} />
      <Route path="about" Component={AboutUs} />
      <Route path="seller" Component={Seller} />
      <Route path="FAQ" Component={FAQ}>
        <Route path="general" />
        <Route path="vendor" />
        <Route path="customers" />
      </Route>
      <Route path="/privacy_policy" Component={Privacy} />
      <Route path="/terms_conditions" Component={Terms} />
      <Route path="/contact" Component={Contact} />
    </Routes >
  );
}

export default Router;
