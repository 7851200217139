import { useState } from "react"

function FeatureUIItems(props) {
   const [headText, setHeadText] = useState(props.headText)
   const [description, setDescription] = useState(props.description)
   return (
      <div
         className="flex flex-col md:flex-row md:justify-between gap-3"
      >
         <div
            className="font-Kumbh500 max-w-[35rem] font-semibold text-primaryDark text-[2.5rem] sm:text-5xl md:text-[3rem]  tracking-tight"
         >
            {headText}:
         </div>
         <div
            className="font-Kumbh400 text-[#333] font-normal text-justify max-w-[38.75rem] text-base sm:text-base md:text-lg md:leading-[1.7rem]"
         >
            {description}
         </div>

      </div>
   )
}

export default function FeatureUI() {
   const policies = [
      {
         headText: "The personal information we collect from you",
         description: "Information you give us: We receive and retain the information you give us, such as your contact information, and delivery address. " +
            "Details about your use of our website and mobile apps: Certain forms of information concerning your use of the Kinra platform, including information about your searches, views, and purchases, are automatically collected and stored by us. " +
            "Third-party information and publicly available sources: Third parties, such as our carriers, payment service providers, merchants/brands, and authentication service providers, may give us information about you. "
      },
      {
         headText: "Data Retention",
         description: "For business purposes and to comply with legal or regulatory obligations, we keep Personal Data in an identifiable format for at least one (1) month. " +
            "If it is in our legitimate business interests and required by applicable legislation, we may keep Personal Data for longer periods of time. In compliance with this Privacy Policy, we shall continue to use and disclose such Personal Data. "
      },
      {
         headText: "How We Use Your Data",
         description: "We collect your personal information in order to provide you with a quick and safe service. " +
            "We use the information you provide to: Process and deliver your orders. Register you as a new customer. " +
            "Allow you to take part in promotions and receive discounts. To improve our website, apps, goods, and services. Recommend products or services that you might be interested in. " +
            "Maintain control of your relationship with us. Comply with our legal requirements, which may include validating your identification."
      }
   ]

   return (
      <div className="flex flex-col gap-24 my-10">
         <div
            className="font-Kumbh500 font-medium m-0 md:text-[1.4rem] lg:text-[1.9rem] px-8 md:px-24  text-[1rem] text-center leading-tight tracking-tight text-primaryDark"
         >
            This privacy policy outlines our commitment to your privacy across all our platforms.
            Its purpose is to tell you about our privacy practices and to help you understand how we manage your personal information.
         </div>
         <div
            className="flex flex-col gap-28"
         >
            {
               policies.map((policy) => (
                  <FeatureUIItems
                     headText={policy.headText}
                     description={policy.description}
                  />
               ))
            }
         </div>
      </div>
   )
}