import FAQbackground from '../../../assets/images/FAQbackground.png';

export default function HeroUI() {
   return (
      <div
         className="w-full mb-10 mt-20"
      >
         <div
            className = "lg:h-[31.7rem] h-[26rem] bg-no-repeat flex flex-col gap-2 lg:gap-8 sm:gap-4  justify-center items-center bg-cover bg-[url('./assets/images/FAQbackground.png')]"
         >
            <div 
               className='font-Kumbh500 font-semibold text-white px-1 lg:text-[4rem] text-center text-[1.7rem] sm:text-3xl md:text-5xl tracking-tight'
            > 
               Welcome to the Help Center
            </div>
            <div 
               className='font-Kumbh400 font-normal text-white px-1 lg:text-[1.5rem] text-sm text-center sm:text-base '
            > 
               We provide quick answers to questions you may have.
            </div>
         </div> 
      </div>
   )
}