import React from 'react';
import { ReactComponent as RocketIcon } from "../../assets/icons/rocketIcon.svg"
import { ReactComponent as RocketIcon2 } from "../../assets/icons/recket2.svg"
import { ReactComponent as Cart } from "../../assets/icons/cart_shopping.svg"
import { ReactComponent as AddAccount } from "../../assets/icons/account_add.svg"
import { ReactComponent as Search } from "../../assets/icons/searchIcon.svg"
import { ReactComponent as Request } from "../../assets/icons/request.svg";
import { ReactComponent as Payment } from "../../assets/icons/payment.svg"
import { ReactComponent as Delivery } from "../../assets/icons/delivery.svg"

const ShoppingJourney = () => {
    return (
        <div className="bg-[#F5F5F5] dark:bg-[#141414] px-6 sm:px-12 py-16 rounded-lg w-full mx-auto">
            <div className='flex flex-row space-x-2'>
                <div>
                    <h1 className="text-3xl sm:text-5xl font-bold dark:text-white sm:leading-7 leading-2">
                        Your Shopping Journey <div className="text-primaryLight md:mt-2"><br /> Starts Here.</div>
                    </h1>
                    <p className="text-gray-600 sm:my-8 my-3">
                        Learn how to effortlessly find, purchase, <br />and enjoy your favorite products.

                    </p>
                </div>
                <RocketIcon className='mt-3' />
            </div>
            <div className="grid md:grid-cols-3 gap-6">
                <div className="bg-white dark:bg-[#1a1a1a] p-6 rounded-lg ">
                    <div className=" p-3 rounded-full inline-block mb-4">
                        <RocketIcon2 className='h-12 w-12' />
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Download the App</h2>
                    <p className="text-gray-600 mt-2">
                        Get the Jekinraa app from the App Store or Google Play Store. Install the app on your device.
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1a1a1a] p-6 rounded-lg ">
                    <div className=" p-3 rounded-full inline-block mb-4">
                        <AddAccount className='h-12 w-12' />
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Create an Account</h2>
                    <p className="text-gray-600 mt-2">
                        Sign up with your email address and create a password. Complete your profile information for a personalized experience.
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1A1A1A] p-6 rounded-lg ">
                    <div className=" p-3 rounded-full inline-block mb-4">
                        <Search className='h-12 w-12 ' />
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Search</h2>
                    <p className="text-gray-600 mt-2">
                        Browse through thousands of products. Use our search and filter options to find your perfect match.
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1A1A1A] p-6 rounded-lg ">
                    <div className=" p-3 rounded-full inline-block mb-4">
                        <Request className='h-12 w-12 ' />
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Make a Request</h2>
                    <p className="text-gray-600 mt-2">
                        Verify the product is in stock and ready to be purchased. Then, add it to your cart and complete your order.
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1A1A1A] p-6 rounded-lg ">
                    <div className=" p-3 rounded-full inline-block mb-4">
                        <Payment className='h-12 w-12 ' />
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Payment</h2>
                    <p className="text-gray-600 mt-2">
                        Verify the product is in stock and ready to be purchased. Then, add it to your cart and complete your order.
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1A1A1A] p-6 rounded-lg ">
                    <div className=" p-3 rounded-full inline-block mb-4">
                        <Delivery className='h-12 w-12 ' />
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delivery</h2>
                    <p className="text-gray-600 mt-2">
                        Choose from a convenient delivery options and stay updated on your order's progress with our tracking system.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ShoppingJourney;
