import FAQUI from "../components/NavBar/FAQ/FAQUI";
import HeroUI from "../components/NavBar/FAQ/HeroUI";
import NavBar from "../layouts/NavBar";
import Footer from "../layouts/Footer";





export default function FAQ() {
   return (
      <div
         className="overflow-x-hidden h-full w-full dark:bg-black"
      >
         <header>
            <NavBar />
         </header>

         <main>
            <HeroUI />
            <section
               className="px-3 sm:px-4 lg:px-20 xl:px-36"
            >
               <FAQUI />

            </section>
         </main>

         <footer>
            <Footer />
         </footer>
      </div>
   )
}
console.log("gary")