import fullhero from '../../../assets/images/fullhero.png';

export default function HeroUI() {
   return (
      <div
         className="w-full mb-10  break-words  mt-36 md:mt-44 dark:bg-black"
      >

         <div
            className="flex flex-col  px-3 sm:px-4 lg:px-20 xl:px-36 md:flex-row gap-5 md:gap-20 lg:justify-between"
         >
            <div
               className="font-Kumbh500 font-medium dark:text-white text-primaryDark max:w-[42rem] text-[2.5rem] sm:text-5xl md:text-6xl tracking-tight leading-tight md:leading-[4.5rem]"
            >
               We provide ease for
               <span
                  className="block text-[#656565]"
               >
                  vendors and customers
               </span>

            </div>
            <div
               className="font-Kumbh400 break-words  text-[#333] dark:text-gray-500 max-w-[25.375rem] text-lg sm:text-lg md:text-xl  md:leading-[1.9rem]"
            >
               Explore a curated destination for entreprenuers, creating a shopping experience tailored to your preferences.
            </div>
         </div>
         <div
            className='mt-20'
         >
            <img
               src={fullhero}
               className="object-cover h-[12.5rem] sm:h-auto w-full "
            />
         </div>
      </div>
   )
}

