import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import SubFAQUI, { UseContext } from "./SubFAQUI"
import { Outlet } from "react-router-dom"
import General from "./subPages/General";



function FAQitems(props) {
   const navigate = useNavigate()
   const location = useLocation()
   const [activeStyle, setActiveStyle] = useState("")
   const [name, setName] = useState(() => props.name)
   const [link, setLink] = useState(() => props.link)

   const active = "border border-primaryLight bg-[rgba(157,0,175,0.04)] text-primaryLight"
   const FaqItems = useContext(UseContext)

   useEffect(() => {
      setName(props.name)
   }, [props.name])

   useEffect(() => {
      setLink(props.link)
   }, [props.link])



   useEffect(() => {
      (location.pathname == "/FAQ/" + props.link) ?
         setActiveStyle(active)
         :
         setActiveStyle("")
   }, [location])

   return (
      <div
         onClick={e => navigate(link)}
         className={" bg-[#F1F1F1] -mb-[0.85rem]  font-Kumbh400 font-normal text-sm md:text-base tracking-tight text-[#222] rounded-3xl md:px-10 px-6 py-1 flex justify-center items-center cursor-pointer " + activeStyle}
      >
         {name}

      </div>
   )
}

export default function FAQUI(props) {


   let questions = [
      {
         name: "General",
         link: "general"
      },
      // {
      //    name:"Vendor", 
      //    link: "vendor"
      // },
      // {
      //    name:"Customers",
      //    link: "customers"
      // }
   ]
   const [content, setContent] = useState("")
   const location = useLocation()
   useEffect(() => {
      (location.pathname == "/FAQ/" + questions[0].link || questions[1].link || questions[2].link) ?
         setContent(<SubFAQUI />)
         :
         setContent("hi")
   }, [location])

   return (
      <div
         className="mt-20 w-full"
      >

         <div
            className="w-full flex flex-col gap-9"
         >
            <div
               className="font-Kumbh500 font-medium md:text-[2rem] lg:text-[2.5rem] text-[1.5rem] tracking-tight text-primaryDark"
            >
               Frequently asked questions
            </div>

            <div
               className="flex gap-5"
            >
               {
                  questions.map((val) => (
                     <FAQitems
                        name={val.name}
                        link={val.link}
                     />
                  ))
               }
            </div>
            <div className="">
               {content}
            </div>
            <Outlet />
         </div>
      </div>
   )
}

