import { initializeApp } from "firebase/app";
//get Analytics instance
import { getAnalytics, isSupported } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCm959OIXrWHRqBfej15NTuIJDByQd8aGM",
    authDomain: "jekinraa-5ce61.firebaseapp.com",
    databaseURL:
        "https://jekinraa-5ce61-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "jekinraa-5ce61",
    storageBucket: "jekinraa-5ce61.appspot.com",
    messagingSenderId: "223189631130",
    appId: "1:223189631130:web:f1c949c309fc46ba9e5f23",
    measurementId: "G-PBSRDRKR3C",
};

const app = initializeApp(firebaseConfig);

const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));

export default app;