import React from 'react';
import { ReactComponent as RocketIcon } from "../../assets/icons/rocketIcon.svg"
import { ReactComponent as RocketIcon2 } from "../../assets/icons/recket2.svg"
import { ReactComponent as Cart } from "../../assets/icons/cart_shopping.svg"
import { ReactComponent as AddAccount } from "../../assets/icons/account_add.svg"
import { ReactComponent as Search } from "../../assets/icons/searchIcon.svg"
import { ReactComponent as Request } from "../../assets/icons/request.svg";
import { ReactComponent as Payment } from "../../assets/icons/payment.svg"
import { ReactComponent as Delivery } from "../../assets/icons/delivery.svg"

const SellerJourney = () => {
    return (
        <div className="bg-[#F5F5F5] dark:bg-[#141414] px-6 sm:px-12 py-16 rounded-lg w-full mx-auto">
            <div className='flex flex-row space-x-2'>
                <div>
                    <h1 className="text-3xl sm:text-5xl font-bold dark:text-white sm:leading-7 leading-2">
                        Why Sell on Kinra?
                    </h1>
                    <p className="text-gray-600 sm:my-8 my-3">
                        With thousands of shoppers actively using Kinra every day,  <br />your products will be seen by the right audience.

                    </p>
                </div>
                <RocketIcon className='mt-3' />
            </div>
            <div className="grid md:grid-cols-3 gap-6 mt-6">
                <div className="bg-white dark:bg-[#1a1a1a] p-6 rounded-lg text-center h-40 justify-center items-center flex flex-col">

                    <h2 className="text-3xl font-semibold text-gray-900 dark:text-white">400+</h2>
                    <p className="text-gray-600 mt-2">
                        Satisfied vendors
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1a1a1a] p-6 rounded-lg text-center h-40 justify-center items-center flex flex-col">

                    <h2 className="text-3xl font-semibold text-gray-900 dark:text-white text-center">10K</h2>
                    <p className="text-gray-600 mt-2">
                        Number of products
                    </p>
                </div>
                <div className="bg-white dark:bg-[#1A1A1A] p-6 rounded-lg text-center h-40 justify-center items-center flex flex-col">
                    <h2 className="text-3xl font-semibold text-gray-900 dark:text-white">4.8/5</h2>
                    <p className="text-gray-600 mt-2">
                        Satisfied customers
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SellerJourney;
