import { useState } from "react"
import { StylesStates } from "../../StylesStates"

export default function HeroUI() {
   const [HeroUI, setHeroUI] = useState(StylesStates.heroUIStyle)
   return (
      <div
         className="w-full h-full"
      >
         <div
            className={"  " + HeroUI}
         >
            <div
               className='w-full font-Kumbh500 font-semibold text-white px-1 lg:text-[4rem] text-center text-[1.7rem] sm:text-3xl md:text-5xl tracking-tight'
            >
               Contact Support
            </div>
            <div
               className='font-Kumbh400 font-normal text-zinc-300 px-1 max-w-[75rem] lg:text-[1.5rem] text-sm text-center sm:text-base leading-4'
            >
               We are here to help you with any questions or concerns you may have. Please feel free to reach out to us.
            </div>
         </div>
      </div>
   )
}