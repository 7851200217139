import enroute from "../../../assets/images/enroute.png";
import phoneL from "../../../assets/images/phoneL.png";
import phoneS from "../../../assets/images/phoneS.png";
import phonepayout from "../../../assets/images/iphonepayout.jpeg";
import phonestats from "../../../assets/images/iphonestats.jpeg";
import phoneCard from "../../../assets/images/cardPage.png";
import world from "../../../assets/images/world.png";

function LeadUIitems(props) {
   return (
      <div className={props?.type == "mini" ? "md:w-2/4" : "md:w-3/4"}>
         <div
            className="min-h-[394px]  flex flex-col relative flex-1 z-1 border-[#E0E0E0] border dark:border-0 dark:bg-[#141414] bg-[#FBFBFB] md:gap-3 shrink rounded-lg px-4  md:px-6 py-5 md:py-8">
            <div
               className="flex flex-1 "
            >
               <div className="flex flex-1 dark:opacity-70">
                  {props.image}
               </div>
            </div>
            <div
               className="font-Kumbh500 font-medium  pb-1 text-[#222] text-lg  md:text-[2rem] dark:text-white">
               {props.headText}
            </div>
            <div
               className="text-[#7E7E7E]  font-Kumbh400 md:leading-normal "
            >
               {props.labelText}
            </div>

         </div>
      </div >
   )
}

export default function LeadUI() {
   let level1 = [

      {
         headText: "Connect with Vendors",
         labelText: "Get connected to hundreds of vendors with different products that meets your needs.",
         type: "mini",
         image: <img
            src={require("../../../assets/images/Reach.png")}
            alt="Map"
            className="md:px-2 w-80 sm:w-64 h-auto object-cover mb-4 mx-auto"

         />
      },
      {
         headText: "Tailored Recommendations",
         labelText: "Discover products you love with personalized recommendations. Our algorithm learns your preferences to suggest items that match your unique style.",
         type: "major",
         image: <img
            src={require("../../../assets/images/Features.png")}
            alt="Map"
            className=" w-full max-h-[204px] object-cover mb-4 rounded-lg"

         />
      },
   ]

   let level3 = [

      {
         headText: "Flexible Delivery and Pickup",
         labelText: "Shop on your own terms with Kinra’s flexible pickup options. Choose to have your order delivered right to your doorstep or pick it up at a convenient location.",
         type: "major",
         image: <img
            src={require("../../../assets/images/Checkout.png")}
            alt="Checkout page"
            className=" w-full max-h-[204px] object-cover mb-4 rounded-lg"
         />
      },
      {
         headText: "Real-time Order Tracking",
         labelText: "Keep an eye on your orders every step of the way.",
         type: "mini",
         image: < img
            src={require("../../../assets/images/Orders.png")}
            alt="Order page"
            className=" w-64 mx-10 max-h-[204px] object-cover mb-4 rounded-lg"
         />
      }
   ]

   return (
      <div
         className="w-full mt-10 "
      >
         <div
            className="flex flex-col gap-10 justify-center items-center"
         >
            <div className="flex flex-col justify-center items-center ">
               <h1
                  className="mt-10 font-bold text-white break-words max:w-[75rem] text-[2.5rem] sm:text-3xl md:text-5xl tracking-tight md:tracking-[0.019rem] leading-tight md:leading-[5.15rem] text-center"
               >
                  Experience Endless Choices and Joy
               </h1>
               <h2
                  title="Seller or Shopper"
                  className="font-Kumbh400 text-center  text-[#F5F5F5]  max-w-[57.9375rem] text-sm sm:text-lg  md:leading-[1.6rem]"
               >
                  Elevate your shopping experience as we introduce you <br />to our platform's uniqueness.
               </h2>
               <div
                  className="flex w-full justify center flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row-reverse sm:gap-10 md:gap-10 lg:gap-10 xl:gap-4 mt-12"
               >
                  {
                     level1.map((val) => (
                        <LeadUIitems
                           headText={val.headText}
                           labelText={val.labelText}
                           image={val.image}
                           type={val.type}
                        />
                     ))
                  }
               </div>

               <div
                  className="my-4 flex w-full justify center flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row-reverse  gap-10 sm:gap-10 md:gap-10 lg:gap-10 xl:gap-4"
               >
                  {
                     level3.map((val) => (
                        <LeadUIitems
                           headText={val.headText}
                           labelText={val.labelText}
                           image={val.image}
                           type={val.type}
                        />
                     ))
                  }
               </div>
            </div>

         </div>
      </div>
   )
}