import android from "../assets/images/android.svg"
import apple from "../assets/images/apple.svg"

const button = {
   primaryBtn: <button

      onClick={() => {
         window.open("https://forms.gle/h7c3EoVZHzgzQqS49", "_blank")
      }}
      className="bg-primaryLight text-center rounded-[2rem] px-4 py-3 sm:py-[0.875rem] sm:px-6 text-bgcolor font-Kumbh500 "
   >
      Join Waitlist
   </button>,
   androidBtn:
      <button
         onClick={() => {
            window.open("https://play.google.com/store/apps/details?id=com.jekinraaapp", "_blank")
         }}
         className="border-primaryLight bg-primaryLight border-2 text-center rounded-lg px-2 py-3 sm:py-[0.875rem] sm:px-4 text-white flex flex-row items-center space-x-2">
         <img src={android} className="h-4 w-4 mr-2" alt="google" style={{
            fill: "white",
            color: "white",
            filter: "brightness(0) invert(1)"
         }} />
         Download Android
      </button>,
   iosBtn:
      <button
         onClick={() => {
            window.open("https://apps.apple.com/app/jekinraa-the-shopping-app/id6478771670", "_blank")
         }}
         className="border-primaryLight  bg-primaryLight border-2 text-center rounded-lg px-2 py-3 sm:py-[0.875rem] sm:px-4 text-white font-Kumbh500 flex flex-row items-center space-x-2">
         <img src={apple} className="h-4 w-4 mr-2" alt="apple" style={{
            fill: "white",
            color: "white",
            filter: "brightness(0) invert(1)"
         }} />
         Download IOS
      </button>,
   secondaryBtn: <button
      onClick={() => {
         window.open("https://forms.gle/h7c3EoVZHzgzQqS49", "_blank")
      }}
      className="bg-bgcolor border border-primaryLight text-center rounded-[2rem] px-4 py-3 sm:py-[0.875rem] sm:px-6 text-primaryLight font-Kumbh500 "
   >
      Join Waitlist
   </button>
}

export { button }