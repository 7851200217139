import { useState } from "react"

function FeatureUIItems(props) {
   const [headText, setHeadText] = useState(props.headText)
   const [formState, setFormState] = useState({
      name: '',
      email: '',
      message: ''
   });

   const handleInputChange = (event) => {
      setFormState({
         ...formState,
         [event.target.name]: event.target.value
      });
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      console.log(formState);
      // Here you would typically send the form data to your server
   };
   return (
      <div
         className="flex flex-col md:flex-row md:justify-between gap-3"
      >
         <div
            className="font-Kumbh500 max-w-[35rem] font-semibold text-primaryDark text-[2.5rem] sm:text-5xl md:text-[3rem]  tracking-tight"
         >
            {headText}:
         </div>
         <div
            className="font-Kumbh400 text-[#333] font-normal text-justify max-w-[38.75rem] text-base sm:text-base md:text-lg md:leading-[1.7rem]"
         >
            <form onSubmit={handleSubmit} className="w-full max-w-sm">
               <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                     Name:
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" name="name" onChange={handleInputChange} />
               </div>
               <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                     Email:
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" name="email" onChange={handleInputChange} />
               </div>
               <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                     Message:
                  </label>
                  <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" name="message" onChange={handleInputChange} />
               </div>
               <div className="flex items-center justify-between">
                  <input className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" value="Submit" />
               </div>
            </form>
         </div>

      </div>
   )
}

export default function FeatureUI() {
   const policies = [
      {
         headText: "Contact Support",
         description: ""
      },

   ]

   return (
      <div className="flex flex-col gap-24 my-10">

         <div
            className="flex flex-col gap-28"
         >
            {
               policies.map((policy) => (
                  <FeatureUIItems
                     headText={policy.headText}
                     description={policy.description}
                  />
               ))
            }
         </div>
      </div>
   )
}