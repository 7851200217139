import { useState } from "react"

function FeatureUIItems(props) {
   const [headText, setHeadText] = useState(props.headText)
   const [description, setDescription] = useState(props.description)
   return (
      <div
         className="flex flex-col md:flex-row md:justify-between gap-3"
      >
         <div
            className="font-Kumbh500 max-w-[35rem] font-semibold text-primaryDark text-[2.5rem] sm:text-5xl md:text-[3rem]  tracking-tight"
         >
            {headText}:
         </div>
         <div
            className="font-Kumbh400 text-[#333] font-normal text-justify max-w-[38.75rem] text-base sm:text-base md:text-lg md:leading-[1.7rem]"
         >
            {description}
         </div>

      </div>
   )
}

export default function FeatureUI() {
   const policies = [
      {
         headText: "Acceptance of Terms",
         description: "By downloading, accessing, or using Kinra App, you agree to be bound by these Terms and Conditions, all applicable laws, and regulations. If you disagree with any part of these terms, you must not use the app."
      },
      {
         headText: "Use of the App",
         description: "a. Kinra App provides a platform for sellers to list their products and for shoppers to browse and purchase goods. b. Users must create an account to use certain features of the app. You agree to provide accurate and complete information when creating your account.c. Users are responsible for maintaining the security of their account and password. Kinra App shall not be liable for any loss or damage arising from your failure to comply with this security obligation."
      },
      {
         headText: "Buying and Selling",
         description: "a. Sellers must accurately represent the products they list on Kinra App. b. Buyers must pay for items purchased promptly and abide by the seller's terms of sale. c. Kinra App is not responsible for any disputes between buyers and sellers, but we encourage users to communicate and resolve conflicts amicably."
      },
      {
         headText: "Intellectual Property",
         description: "a. The content and design of Kinra App are protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from the app without prior written permission. b. By submitting content (such as product listings) to Kinra App, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, and display the content in connection with the app."
      },
      {
         headText: "Limitation of Liability",
         description: "a. Kinra App is provided ' as is' and without warranties of any kind, whether express or implied. b.In no event shall Kinra App or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the app."
      },
      {
         headText: "Amendments",
         description: "a. Kinra App reserves the right to modify or replace these Terms and Conditions at any time. It is your responsibility to review these Terms and Conditions periodically for changes. b. Your continued use of Kinra App following the posting of any changes to these Terms and Conditions constitutes acceptance of those changes."
      },
      {
         headText: "Governing Law",
         description: "a. These Terms and Conditions shall be governed by and construed in accordance with the laws of Federal Republic of Nigeria, without regard to its conflict of law provisions.b. Any dispute arising out of or related to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Nigeria."
      }
   ]

   return (
      <div className="flex flex-col gap-24 my-10">
         <div
            className="font-Kumbh500 font-medium m-0 md:text-[1.4rem] lg:text-[1.9rem] px-8 md:px-24  text-[1rem] text-center leading-tight tracking-tight text-primaryDark"
         >
            By accessing this app, we assume you accept these Terms and Conditions in full.
            Do not continue to use Kinra App if you do not accept all of the Terms and Conditions stated on this page.
         </div>
         <div
            className="flex flex-col gap-28"
         >
            {
               policies.map((policy) => (
                  <FeatureUIItems
                     headText={policy.headText}
                     description={policy.description}
                  />
               ))
            }
         </div>
      </div>
   )
}