function TeamUIitems(props) {
   let aspectRatio = 1 / 1.3;
   return (
      <div className="flex flex-col flex-1 mb-6 space-y-4 basis-1/5 my-6 sm:my-3 h-[32rem] overflow-hidden rounded-lg">
         <img className='bg-white border border-[#D9D9D950] dark:border-0 rounded  h-[24rem]  w-full object-cover rounded-lg'
            style={{
            }}
            src={
               props?.image
            }
            alt={
               props.name
            }
         >
         </img>
         <div
            className="h-12"
         >
            <div
               className="text-xl font-medium tracking-tight font-Kumbh500 text-primaryDark dark:text-gray-500"
            >
               {props.name}
               <div
                  className="text-[#4F4F4F] font-normal tracking-normal text-lg font-Kumbh400"
               >
                  {props.title}
               </div>
            </div>
         </div>
      </div >


   )
}

export default function TeamUI() {

   let teams = [
      {
         name: "Precious Vincent",
         title: "CEO",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2FIMG_2494%202.jpg?alt=media&token=5bc109bd-3a47-4828-917c-c84df8d3b4d5&_gl=1*1a7bchp*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU4OTgwLjQ4LjAuMA.."
      },
      {
         name: "David Ajibade",
         title: "COO",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2FWhatsApp%20Image%202024-09-25%20at%2011.12.12.jpeg?alt=media&token=16577c83-397d-449f-834a-a78ec85e70ee"
      },
      {
         name: "Israel Akin-Akinsanya",
         title: "CTO",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2F1696074791802.jpeg?alt=media&token=33e454e6-cc94-4a55-8cfa-b7fbb67a1106&_gl=1*tg6xy8*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU5NDUyLjQ4LjAuMA.."
      },
      {
         name: "Oyebola Simeon",
         title: "Market Analyst",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2F1694777099691.jpeg?alt=media&token=832e6b85-0aca-4f58-931a-b14e52e387fa&_gl=1*gj1uie*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU5Nzg3LjI2LjAuMA.."
      },
      {
         name: "Olubanwo Ayomide",
         title: "Development",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2F1676386272810.jpeg?alt=media&token=28a45fb2-bb79-4150-b648-9ef80f86ff93&_gl=1*egv63a*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU5NTY4LjUzLjAuMA.."
      },
      {
         name: "Olalekan Daramola",
         title: "UX Director",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2F1683235026387.jpeg?alt=media&token=8d815a81-c9f9-4cfb-95c3-c4674be8481a&_gl=1*uz8k9q*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU5NzY3LjQ2LjAuMA.."
      },
      {
         name: "Idowu Matthew",
         title: "Operations Manager",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2FIMG_2891%20-%20MATTHEW%20ADENIYI%20IDOWU.jpeg?alt=media&token=f3463161-1822-4863-88b7-fdc548666221&_gl=1*1kjm0zb*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU3NTEzLjYwLjAuMA.."
      },
      {
         name: "Ogundana Ayomide",
         title: "Social Media Manager",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2F1691365610637_1.jpg?alt=media&token=bf774a6b-805b-4b6f-bb26-480a217a2186"
      },
      {
         name: "Adepoju Esther",
         title: "Ambassador",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2F1701842395326.jpeg?alt=media&token=017504d3-eeb5-42ca-acfd-04e14d1fca8a"
      },
      {
         name: "Amore Olaoluwa",
         title: "Operations",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2FSnapchat-429645538%20-%20Amore%20Olaoluwa.jpg?alt=media&token=b19f1d6d-dfde-406e-b96d-657328ef1461&_gl=1*v0mu98*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU4MzkwLjYwLjAuMA.."
      },
      {
         name: "Habeeb Muhammed Olanrewaju",
         title: "Backend Developer",
         image: "https://firebasestorage.googleapis.com/v0/b/jekinraa-5ce61.appspot.com/o/Team%2Fme%20-%20Muhammed%20Olanrewaju.png?alt=media&token=e120b7db-2e8f-479f-8f41-d7218b62236a&_gl=1*1xrtvhi*_ga*OTA0ODgyOTU0LjE2NjIyMDU4Mjg.*_ga_CW55HF8NVT*MTY5ODg1NjM2OS4xNTcuMS4xNjk4ODU4NTg5LjYwLjAuMA.."
      }
   ]

   return (
      <div
         className="md:pb-36 md:mb-36 pb-20 mb-28 mt-28 md:mt-44 dark:bg-black"
      >
         <div
            className="flex flex-col gap-8  items-center md:items-start"
         >
            <div
               className="font-Kumbh500 max-w-[24.75rem] font-semibold  text-primaryDark text-[2.5rem] sm:text-5xl md:text-[3.5rem]  tracking-tight"
            >
               Meet our team
            </div>
            <div className="flex flex-col flex-wrap md:flex-row justify-between w-full gap-6">
               {
                  teams.map((val) => (
                     <TeamUIitems
                        name={val.name}
                        title={val.title}
                        image={val?.image}
                     />
                  ))
               }
            </div>
         </div>
      </div>
   )
}