import FeatureUI from "../components/Footer/Support/PrivacyPolicy/FeatureUI";
import HeroUI from "../components/Footer/Support/PrivacyPolicy/HeroUI";
import Footer from "../layouts/Footer";
import NavBar from "../layouts/NavBar";



export default function Privacy() {
   return (
      <div
         className="overflow-x-hidden h-full w-full"
      >
         <NavBar />
         <main
            className=""
         >
            <HeroUI />
            <section
               className="px-3 sm:px-4 lg:px-12 xl:px-20"
            >
               <FeatureUI />
            </section>
         </main>
         <Footer />
      </div>

   )
} 
